<template>
  <div>
    <Row :gutter="8" class="m-b-10">
      <i-col span="3">
        <Select size="small" placeholder="选择工作时段" v-model="query.workTimeType" transfer clearable>
          <i-option v-for="item in timeTypeArray" :key="'timeType_' + item.value" :value="item.value">
            {{ item.name }}
          </i-option>
        </Select>
      </i-col>
      <i-col span="3">
        <Select size="small" placeholder="选择优先级" v-model="query.priorityLevel" transfer clearable>
          <i-option v-for="item in priorityLevelArray" :key="'level_' + item.value" :value="item.value">
            {{ item.name }}
          </i-option>
        </Select>
      </i-col>
      <i-col span="3">
        <Select size="small" placeholder="选择员工类型" v-model="query.staffType" transfer clearable>
          <i-option v-for="item in staffTypeArray" :key="'staffType_' + item.value" :value="item.value">
            {{ item.name }}
          </i-option>
        </Select>
      </i-col>
      <i-col span="4">
        <i-input v-model.trim="query.keyword" size="small" placeholder="关键字：姓名"></i-input>
      </i-col>
      <i-col span="6">
        <Button type="primary" icon="ios-search" size="small" class="m-r-5" @click="onSearch">搜索</Button>
        <Button type="success" size="small" class="m-r-5" @click="onAddUser">新建带班人员</Button>
        <Button type="success" size="small" class="m-r-5" @click="onDownloadFile">导出Excel</Button>
      </i-col>
    </Row>
    <div>
      <Table stripe max-height="620" size="small" :data="tableList" :columns="tableColumns" :loading="tableLoading">
      </Table>
      <div class="paging_style">
        <Page size="small" :total="total" :page-size="query.pageSize" show-total show-elevator show-sizer
          :page-size-opts="pagesizeSetting" @on-page-size-change='onChangePageSize' :current="query.pageNumber"
          @on-change="onChangePageNum"></Page>
      </div>
    </div>
    <edit v-if="showEdit" ref="edit" :optionType="optionType" @refreshTable="getTableData" />
    <detail v-if="showDetail" ref="detail" />
    <!-- <conf-station
      v-if="showConfStation"
      ref="confStation"
      @refreshTable="getTableData"
    /> -->
  </div>
</template>

<script>
import { downloadFileRequest } from '@/utils/download'
import { indexMixins } from '../mixins'
import { getWorkdayUserPage, setUserUnusable } from '@/api/msp/workdayUser'

import Edit from './Edit.vue'
import Detail from './Detail.vue'
// import ConfStation from './ConfStation.vue'
export default {
  mixins: [indexMixins],
  components: {
    Edit,
    Detail
    // ConfStation
  },
  data () {
    return {
      pagesizeSetting: [15, 50, 100, 200, 500],
      optionType: 1, // 操作类型，1-新增，2-编辑
      query: {
        pageNumber: 1,
        pageSize: 15,
        priorityLevel: undefined,
        staffType: undefined,
        workTimeType: undefined,
        keyword: ''
      },
      tableLoading: false,
      tableList: [],
      total: 0,
      tableColumns: [
        { title: '姓名', align: 'center', minWidth: 120, key: 'name' },
        { title: '联系方式', align: 'center', minWidth: 120, key: 'phone' },
        { title: '居住地址', align: 'center', minWidth: 120, key: 'addressInfo' },
        { title: '工作时段', align: 'center', minWidth: 120, key: 'workTimeTypeStr' },
        { title: '优先级', align: 'center', minWidth: 120, key: 'priorityLevelStr' },
        { title: '类型', align: 'center', minWidth: 120, key: 'staffTypeName' },
        // {
        //   title: '工作站点配置',
        //   align: 'center',
        //   minWidth: 120,
        //   key: 'isSetStation',
        //   render: (h, { row }) => {
        //     return h('span', row.isSetStation ? '已配置' : '未配置')
        //   }
        // },
        {
          title: '操作',
          align: 'center',
          minWidth: 180,
          fixed: 'right',
          render: (h, { row }) => {
            const html = [
              h('a', {
                class: 'm-r-5',
                on: {
                  click: () => {
                    this.onViewDetail(row)
                  }
                }
              }, '详情'),
              h('a', {
                class: 'm-r-5',
                on: {
                  click: () => {
                    this.onEdit(row)
                  }
                }
              }, '编辑'),
              // h('a', {
              //   class: 'm-r-5',
              //   on: {
              //     click: () => {
              //       this.onSetStation(row)
              //     }
              //   }
              // }, '站点配置'),
              h('a', {
                class: 'm-r-5',
                style: {
                  color: '#ef4f4f'
                },
                on: {
                  click: () => {
                    this.onDelete(row)
                  }
                }
              }, '删除')
            ]

            return h('div', html)
          }
        }
      ],
      showEdit: false,
      showDetail: false,
      showConfStation: false
    }
  },
  created () {
    this.getTableData()
  },
  methods: {
    async getTableData () {
      this.tableLoading = true
      const result = await getWorkdayUserPage(this.query).finally(() => { this.tableLoading = false })

      this.tableList = result && !result.errcode ? result.list : []
      this.total = result && !result.errcode ? result.totalRow : 0
    },
    onChangePageNum (curPage) {
      this.query.pageNumber = curPage
      this.getTableData()
    },
    onChangePageSize (curPageSize) {
      this.query.pageNumber = 1
      this.query.pageSize = curPageSize
      this.getTableData()
    },
    onSearch () {
      this.query.pageNumber = 1
      this.getTableData()
    },
    onAddUser () {
      this.optionType = 1
      this.showEdit = true
      this.$nextTick(() => {
        this.$refs.edit.showModal()
      })
    },
    onDownloadFile () {
      const query = {
        keyword: this.query.keyword,
        priorityLevel: this.query.priorityLevel,
        staffType: this.query.staffType,
        workTimeType: this.query.workTimeType
      }
      downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-msp/v1/workdayReport/getWorkdayUserExcel', query, '带班人员明细')
    },
    /**
     * 详情查看
     * @param params
     */
    onViewDetail (params) {
      this.showDetail = true
      this.$nextTick(() => {
        this.$refs.detail.showModal(params.id)
      })
    },
    /**
     * 编辑
     * @param params
     */
    onEdit (params) {
      this.optionType = 2
      this.showEdit = true
      this.$nextTick(() => {
        this.$refs.edit.showModal(params)
      })
    },
    // /**
    //  * 配置站点
    //  * @param params
    //  */
    // onSetStation (params) {
    //   this.showConfStation = true
    //   this.$nextTick(() => {
    //     this.$refs.confStation.showModal(params)
    //   })
    // },
    /**
     * 删除人员
     * @param params
     */
    onDelete (params) {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定要删除？',
        onOk: async () => {
          const result = await setUserUnusable({ userId: params.id })
          if (result && result.success) {
            this.$Notice.success({ desc: '操作成功' })
            this.getTableData()
          }
        }
      })
    }
  }
}
</script>
